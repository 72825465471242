import '@/styles/globals.css';
import '../../node_modules/@rimactechnology/rimac-ui-lib/dist/style.css';

import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import { useApollo } from '@/api/apolloClient';
import { CookieBar } from '@/components/cookies/CookieBar';
import { CookieSetting } from '@/components/cookies/CookieSetting';
import { Impressum } from '@/components/impressum/Impressum';
import { AnalyticsProvider } from '@/components/providers/AnalyticsProvider';

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps);
  // const articleClient = useArticleApollo(pageProps);

  return (
    <ApolloProvider client={client}>
      {/* <ApolloProvider client={articleClient}> */}
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Rimac Energy</title>
      </Head>
      <AnalyticsProvider>
        <Component {...pageProps} />
        <CookieBar key={'cookies-bar'} />
        <CookieSetting key={'cookies-settings'} />
        <Impressum key="impressum" />
      </AnalyticsProvider>
      {/* </ApolloProvider> */}
    </ApolloProvider>
  );
};

export default App;
