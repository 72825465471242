import { CookiesBar, CookiesBarProps } from '@rimactechnology/rimac-ui-lib';
import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

import { useCookiesBarQuery } from '@/api';
import { cookieExpirationDate } from '@/constants/cookies';

export const CookieBar = () => {
  const [visible, setVisible] = useState(false);
  const { data } = useCookiesBarQuery();
  const cookiesBarData = data?.cookieBanner?.data?.attributes;

  useEffect(() => {
    hasCookie('renergy_cookie_consent_necessary') &&
    getCookie('renergy_cookie_consent_necessary') === 'true'
      ? setVisible(false)
      : setVisible(true);
  }, []);

  const declineOptional = () => {
    setCookie('renergy_cookie_consent_necessary', true, {
      expires: cookieExpirationDate,
    });
    setVisible(false);
  };

  const setAll = () => {
    setCookie('renergy_cookie_consent_necessary', true, {
      expires: cookieExpirationDate,
    });
    setCookie('renergy_cookie_consent_optional', true, {
      expires: cookieExpirationDate,
    });
    setVisible(false);
  };

  const cookieBarProps: CookiesBarProps = {
    useCase: 'energy',
    heading: cookiesBarData?.heading || '',
    text: cookiesBarData?.text,
    editButton: {
      label: cookiesBarData?.editButtonLabel || '',
      path: '#cookiesSettings',
      useCase: 'form',
    },
    declineButton: {
      label: cookiesBarData?.declineButtonLabel || '',
      onClick: declineOptional,
      useCase: 'form',
    },
    acceptButton: {
      label: cookiesBarData?.acceptButtonLabel || '',
      onClick: setAll,
      useCase: 'energy',
    },
    extraLinks: (cookiesBarData?.extraLinks || []).map((link) => ({
      isTextLink: true,
      label: link?.label || '',
      path: link?.path || '',
    })),
  };

  if (visible && data)
    return (
      <div className="fixed bottom-none left-1/2 z-10 w-full -translate-x-1/2">
        <CookiesBar {...cookieBarProps} />
      </div>
    );
};
